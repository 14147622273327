.preload{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--card-bg-neutral);
	z-index: 10;
	display: grid;
	place-content: center;
	justify-items: center;
	transition: var(--transistion-2);
}

.preload > * {
	transition: var(--transistion-1)
}

.preload.loaded > * {
	opacity: 0;
}

.preload.loaded {
	transition-delay: 250mS;
	transform: translateY(100%);
}

.circle{
	width: 112px;
	height: 112px;
	border-radius: 50%;
	border: 10px solid var(--grey-dark);
	border-block-start-color: var(--logo-yellow);
    
	margin-block-end: 45px;
	animation: rotate360 5s linear infinite;
}

@keyframes rotate360{
	0% {transform: rotate(0);}
	100% {transform: rotate(1turn);}
}

.text{
    
	/* background-image: linear-gradient(90deg,transparent 0% 16.66%, var(--grey-dark) 33.33% 50%, transparent 66.66% 100%); */ 
	text-align: center;
    background-image: linear-gradient(90deg,transparent 0% 15%, var(--grey-dark) 30% 50%, transparent 85% 100%);
	background-size: 500%;
	font-size: calc(2rem + 3vw);
	font-weight: var(--weight-bold);
	line-height: 1em;
	text-transform: uppercase;
	letter-spacing: 3px;
	padding-inline-start: 16px;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-stroke: 0.5px var(--black);
	animation: loadingText 5s linear infinite;
}

@keyframes loadingText{
	0% {background-position: 100%;}
	100% {background-position: 0%;}
}
